import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import { AutoCompleteNew } from 'components/Select'
import { UserSearchKindOptions } from 'common/enum'
import React, { useCallback, useMemo, useState } from 'react'
import BooleanToggleButtonGroup from 'components/BooleanToggleButtonGroup'

const UsersToolbar = props => {
  const { onSearchChange, params } = props
  const [prevKind, setPrevKind] = useState(null)

  const [kind, value] = useMemo(() => {
    const foo = ['email', 'userId', 'pfUserId'].find(k => !!params[k]) || prevKind || 'userId'
    return [foo, params[foo]]
  }, [params, prevKind])

  const handleKindChange = useCallback((k) => {
    k = k || 'userId'
    setPrevKind(k)
    return onSearchChange({ [kind]: undefined, [k]: value })
  }, [kind, onSearchChange, value])

  const handleValueChange = useCallback(v => {
    return onSearchChange({ [kind]: v }, 800)
  }, [kind, onSearchChange])

  return (
    <div className={'tool-bar'}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <AutoCompleteNew name='kind-autocomplete' label='' disableUnderline={false} value={kind} options={UserSearchKindOptions} inputLeft={true} variant='outlined'
            className='background-color' onChange={(event, selected) => handleKindChange(selected && selected.value)}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField name='query' label='' fullWidth variant='outlined' size='small' className='background-color' value={value} onChange={event => handleValueChange(event.target.value)} />
        </Grid>

        <Grid item xs={2}>
          <BooleanToggleButtonGroup withoutNull={true} label='Hide Users Without Email' name='with_email' value={!!+params.emailFilter}
            onChange={(key, val) => onSearchChange({ emailFilter: +val })} />
        </Grid>
        
        <Grid item xs={4} />
      </Grid>
    </div>
  )
}

UsersToolbar.propTypes = {
  className: PropTypes.string,
}

export default UsersToolbar
